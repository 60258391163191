<template>
  <div
    class="group relative"
    :title="props.title"
  >
    <div
      class="relative flex min-h-[22rem] items-end overflow-y-hidden transition-all before:absolute before:block before:size-full before:translate-y-24 before:bg-white before:transition before:duration-500 group-hover:before:translate-y-0 lg:min-h-[35rem] lg:before:translate-y-40"
    >
      <div
        class="relative flex w-full flex-col justify-between px-7 pb-8 pt-12 lg:pt-24"
      >
        <div
          class="absolute left-0 top-0 aspect-product-image-mobile w-full -translate-y-2/4 overflow-hidden px-7 lg:aspect-product-image"
        >
          <UiImage
            v-if="props.image?.src"
            :src="props.image.src"
            width="300"
            height="170"
            class="size-full object-contain"
          />
        </div>

        <div class="z-10">
          <UiTag
            v-if="props.tag"
            :tag="props.tag"
            class="mb-3"
          />
          <p
            v-if="props.title"
            class="mb-2.5 line-clamp-2 text-lg font-bold text-primary transition-all duration-500 xl:mb-3.5 xl:line-clamp-5 xl:max-h-36 xl:min-h-[8.5rem] xl:text-xl xl:group-hover:max-h-44"
          >
            {{ props.title }}
          </p>
          <p
            v-if="props.description"
            class="line-clamp-2 text-base font-normal transition-all duration-500 xl:line-clamp-4 xl:max-h-12 xl:min-h-12 xl:group-hover:max-h-24"
          >
            {{ props.description }}
          </p>
        </div>
        <!-- slot for button -->
        <div>
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import UiTag from '../UiTag/UiTag.vue'
import UiImage from '../UiImage/UiImage.vue'
import type { ImageInterface } from '../UiImage/UiImage.types'

interface UiFeatureBoxProps {
  image: ImageInterface
  title: string | null
  description: string
  tag?: string
}

const props = defineProps<UiFeatureBoxProps>()
</script>
